import React from 'react';
import './appAlert.css'
import AppError from '../AppLanguage/AppError';
const Alert = ({ message,code,onClose,getCountry }) => {

    const selectedError = AppError[localStorage.getItem('country')||'en'].errMenu
    let errCodeText=selectedError.felkod
    let btnMessage=selectedError.btnMessage
   


  return (
    <div className="alert-overlay">
      <div className="alert-content">
        <div className='alert-message'>{message+'!'}</div>
        {/* <div className='alert-messageErrCode'>{code>0?errCodeText+code:''}</div> */}
        
        <button className='alert-button' onClick={onClose}>{btnMessage}</button>
      </div>
    </div>
  );
};

export default Alert;