import React, { useState,useEffect } from 'react';
import './Login.css';
import { usePostData1,usePostData } from '../dataconn/getData';
import { useNavigate } from 'react-router';
import Alert from '../Alert/appAlert';
import {useReportErr,useReportErr1} from '../Report/useReportErr';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import { RotatingLines } from  'react-loader-spinner'
import { jwtDecode } from 'jwt-decode';
function Login() {

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const[getConfirmedPass, setConfirmedPass]=useState('');
  const [getUserName, setUserName] = useState('');
  const [getPassword, setPassword] = useState('');
  const [timediff, setTimeDiff] = useState(0);
  const [getshowAlert, setShowAlert] = useState(false);
  const [getAlertMessage, setAlertMessage] = useState('');
  const [getAlertCode, setAlertCode] = useState(0);
  const [getCountry, setCountry] = useState('');

useEffect(() => {
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
}, []);

let navigate = useNavigate();
const selectedCountry = languages[getCountry];

const selectedError=AppError[localStorage.getItem('country')||'en']

const RunReportErrHandler = async (errCode) => {
      
  setSpinnerLoading(true);
  await useReportErr1('errReportAdmin',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);

const handleCloseAlert = () => {
  setShowAlert(false);
};


async function LoginHandler() {
  setSpinnerLoading(true);
  const apiKey=process.env.REACT_APP_ADMINKEY
  try{
      const response = await usePostData1('loginAdmin', { email:getUserName, password:getPassword },
      apiKey);

    
    if(response.status===200){
      setSpinnerLoading(false);
      localStorage.setItem('auth_token', response.data.token);
      const decodedToken = jwtDecode(response.data.token);
      
      const country = decodedToken.country;
          // Store the countryC value in local storage
          localStorage.setItem('country', country);

      setTimeDiff(response.data.timediff);
      setShowConfirmPass(true);

    }else if(response.status===290){
      setAlertCode(400000);
      setAlertMessage(selectedError[400001])
      setSpinnerLoading(false);
      setShowAlert(true);

    }


}catch(error){
  console.log(error);
}
}

async function ConfirmLoginHandler() {
  console.log('confirm login handler');
  setSpinnerLoading(true);

  try{
 const response=await usePostData(`confirmAdmin`, {confirmedPassword:getConfirmedPass, email:getUserName,timediff:timediff});

 if(response.status===200){
navigate('/Panel');

 }else if(response.status===290 || response.status===500){
  setAlertCode(400001);
  setAlertMessage(selectedError[400001])
  setSpinnerLoading(false);
  setShowAlert(true);
 }

}catch(error){
  setAlertCode(400000);
  setAlertMessage(selectedError[400001])
  setSpinnerLoading(false);
  setShowAlert(true);
}
}

const setConfirmedPassHandler=(value)=>{
  setConfirmedPass(value);
}









    return (
      <><div className="loginTop">
      <div className="loginBox">
        <div className="loginTitle">Bakesoft Panel</div>
        <input type = "text" placeholder="Username" 
        className="loginInput"
        onChange={(e)=>setUserName(e.target.value)}
        ></input>
        <input type = "password" placeholder="Password" 
        className="loginInput"
        onChange={(e)=>setPassword(e.target.value)} />

        {showConfirmPass?
        <>
        <input type="password" placeholder="Confirm Password" 
        className="loginInput"
        onChange={(e)=>setConfirmedPassHandler(e.target.value)} />
              
                <button className="confirmloginButton"
                onClick={() => ConfirmLoginHandler()}
                >confirm Login</button>
          </>
          :null}
        

        {!showConfirmPass && 
        <button className="loginButton" 
        onClick={()=>LoginHandler()}
        disabled={!getPassword || getPassword.length < 1 || getUserName.length < 1}
        
        >Login</button>}
 
      </div>
      </div>
      
<div className="adminSpinnerContainer">
        <div className="adminSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={spinnerLoading}/>
        </div>
        </div>

      
      {getshowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
    
      </>
   
    );
}

export default Login;

