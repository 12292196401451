
import axios from "axios";



  async function useReportErr(url, payload) {
    const token = localStorage.getItem('auth_token');
    const headers = token ? { authorization: `Bearer ${token}`} : {};
  
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/${url}`, payload, {
      headers: headers,
      Credentials: true
    });
  
    return result;
    
  }


  async function useReportErr1(url, payload) {
    const token = localStorage.getItem('auth_token');
    const headers = {'x-api-key':process.env.REACT_APP_ERRREPORTKEY};
  
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/${url}`, payload, {
      headers: headers,
      Credentials: true
    });
  
    return result;
    
  }

  export {useReportErr,useReportErr1};