const languages = {
  se: {
      common: {
          greeting: "Hej",
          farewell: "Hej då"
      },
      confirmationMessages: {
          downloadpdfOkMessage: "Fakturan har laddats ner",
          userTurnedOffMessage: "Användaren har stängts av",
          userTurnedOnMessage: "Användaren har slagits på",
          serviceTurnedOffText: "Tjänsten har stängts av",
          serviceTurnedOnText: "Tjänsten har slagits på",
          serviceProlongedText: "Tjänsten har förlängts",
          signUpStatusUpdateText:"Status har uppdaterats",
          supportTicketUpdateOkText:"Support ticket har uppdaterats",
        },
        panel:{
            supportStatusText:"Support status",
            supportCategoryText:"Support kategori",
        }
  },
  ja: {
      common: {
          greeting: "こんにちは", // "Hello" in Japanese
          farewell: "さようなら" // "Goodbye" in Japanese
      },
      confirmationMessages: {
          downloadpdfOkMessage: "請求書がダウンロードされました",
          userTurnedOffMessage: "ユーザーがオフになりました",
          userTurnedOnMessage: "ユーザーがオンになりました",
          signUpStatusUpdateText:"ステータスが更新されました",
            supportTicketUpdateOkText:"サポートチケットが更新されました",
      },
      panel:{
        supportStatusText:"サポートステータス",
        supportCategoryText:"サポートカテゴリ",
    }
  },
  en: {
      common: {
          greeting: "Hello",
          farewell: "Goodbye"
      },
      confirmationMessages: {
          downloadpdfOkMessage: "Invoice has been downloaded",
          userTurnedOffMessage: "User has been turned off",
          userTurnedOnMessage: "User has been turned on",
          signUpStatusUpdateText:"Status has been updated",
            supportTicketUpdateOkText:"Support ticket has been updated",
      },
      panel:{
        supportStatusText:"Support status",
        supportCategoryText:"Support category",
    }
  },
};

export default languages;