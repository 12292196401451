import React,{useState,useEffect,useContext} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './login/login';
import Panel from './Panel/Panel';

//components



//helloes again we know
export default function Layout() {
    return (
      <div className="indexTop">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Panel" element={<Panel />} />
  
          </Routes>
        </Router>
      </div>
    );
  }